<template>
  <div class="nav-bar">
    <van-nav-bar :border="border" :title="title">
      <template #left>
        <van-icon name="cross" @click="goBack" />
      </template>
    </van-nav-bar>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  props: {
    title: {
      type: String,
      default: '',
    },
    border: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goBack() {
      window.history.go(-1);
    },
  },
};
</script>
