import {Base64} from "js-base64";

export function trackClickRegister( data = {}, afterSend) {
  $alTrack('ClickRegister', function (pageInfo) {
    let ret = { }
    console.log(pageInfo)
    return Object.assign(ret, data)
  }, function () {
    if (afterSend) {
      afterSend()
    }
  })
}

export function $alCollectPageInfo(pageName, pageHref) {
  sessionStorage.removeItem('collect-page-info')
  let lastPageName = sessionStorage.getItem('last-page-name') || ''
  let lastPageHref = sessionStorage.getItem('last-page-href') || ''
  let obj = {
    forward_page_address: lastPageHref,
    forward_page_name: lastPageName,
    page_address: pageHref,
    page_name: pageName
  }
  sessionStorage.setItem('collect-page-info', JSON.stringify(obj))
}

export function getCollectPageInfo() {
  return sessionStorage.getItem('collect-page-info')&&JSON.parse(sessionStorage.getItem('collect-page-info')) 
}

export let pageDef = {
  platform_type: 'h5',
  application_name: '研途M站',
  application_version: 'v1.0.2',
  is_login: false
}

export function getPresetPropsWithBase64Format(data = {}) {
  let userCustomData = {
    ...getCollectPageInfo(),
    anonymous_id: window.sensors.quick('getAnonymousID'),
    ...pageDef,
    ...data
  }
  console.log(userCustomData);
  return {
    ['x-yt-dbp-cus']: Base64.encode(JSON.stringify(userCustomData)),
    ['x-yt-dbp-pre']: Base64.encode(JSON.stringify(getPresetProperties()))
  }
}

export function $alTrack(eventName, cb, afterSend) {
  let pageInfo = sessionStorage.getItem('collect-page-info')
  let data = cb(pageInfo)
  if (window.sensors && window.sensors.track && window.sensors.getPresetProperties) {
    window.sensors.track(eventName, data, function () {
      if (afterSend) {
        afterSend()
      }
    })
  } else {
    console.error('sensors track initfail')
    if (afterSend) {
      afterSend()
    }
  }
}

export function getPresetProperties() {
  let properties =  {}
  if (window.sensors && window.sensors.getPresetProperties) {
    properties = window.sensors.getPresetProperties()
  }
  // FIXIT this is a trap
  delete properties._distinct_id;
  return properties
}

export function startTrackApp(pageName) {
  window.sensors.registerPage(pageDef);
  window.sensors.quick('autoTrack', pageDef);
  setTimeout(() => {
    $alCollectPageInfo(pageName, location.origin + location.pathname)
  }, 500)
}
