<template>
  <van-field
    clearable
    type="tel"
    maxlength="11"
    @input="onChange"
    v-model="mobile"
    placeholder="请输入手机号码" />
</template>

<script>
export default {
  name: 'phoneInput',
  props: {
    needCode: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      mobile: ''
    }
  },
  methods: {
    onChange(e) {
      this.$parent.form.mobile = e
      if(this.needCode) {
        this.$parent.disCode = !this.$isMobile(e)
      }
    }
  }
}
</script>