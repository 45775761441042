import axios from 'axios'
import config from '@/config'
import Toast from 'vant/lib/toast/index'
import { getDomainCookie } from '@/utils/auth'
import { Base64 } from 'js-base64'

// 创建axios实例
const service = axios.create({
  baseURL: config.ajaxBaseUrl,
  headers: {
    'AUTH-SYS': 'mstation',
    UUID: 123456,
    platform: 'mstation',
    ytversion: 'v1.0.2',
    'x-yt-application': 'vip',
  },
  timeout: 5000, // 请求超时时间
})

function getQueryString(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  var r = window.location.search.substr(1).match(reg)
  if (r != null) return unescape(r[2])
  return null
}

// request拦截器
service.interceptors.request.use(
  (config) => {
    var tq = sessionStorage.getItem('tq') || ''
    var anonymous_id = ''
    if (window.sensors && window.sensors.quick) {
      anonymous_id = window.sensors.quick('getAnonymousID') || ''
    }
    var corss = getDomainCookie('sensorsdata2015jssdkcross') || ''
    var distinct_id = ''
    if (corss) {
      distinct_id = JSON.parse(decodeURIComponent(corss)).distinct_id
    }
    let starter_id = getQueryString('starter_id')
    if (starter_id) {
      config.headers['x-yt-extr-biz'] = Base64.encode(
        JSON.stringify({ starter_id })
      )
    }
    config.headers['x-yt-anonymousid'] = anonymous_id
    config.headers['x-yt-sensor-anonymous'] = anonymous_id
    config.headers['x-yt-sensor-distinct'] = distinct_id
    config.headers['x-yt-trace-cp'] = encodeURIComponent(window.location.href)
    config.headers['x-yt-trace-lp'] = tq

    if (getQueryString('org')) {
      config.headers['org'] = getQueryString('org')
    }
    return config
  },
  (error) => {
    // Do something with request error
    Promise.reject(error)
  }
)

// respone拦截器
service.interceptors.response.use(
  (response) => {
    /**
     * code为非20000时抛错 可结合自己业务进行修改
     */
    const res = response.data
    if (res.code === 20000) {
      return res
    } else {
      if (res.code != 61003) {
        // 用户已存在  使用自定义提示弹窗
        Toast(res.msg || '网络错误')
      }
      return Promise.reject(res)
    }
  },
  (error) => {
    Toast(error.message)
    return Promise.reject(error)
  }
)

export default service
