import request from '@/api/request'
// import config from '@/config'
import md5 from 'licia/md5'
import { getPresetPropsWithBase64Format } from '../tongji'

function resolvePassword(data) {
  let _data = JSON.parse(JSON.stringify(data))
  if (_data.password) {
    _data.password = md5(_data.password)
  }
  if (_data.new_password) {
    _data.new_password = md5(_data.new_password);
  }
  return _data
}
// 登录
export function login(data, params = {}) {
  let _data = resolvePassword(data)
  return request({
    url: '/auth/v1/public/login/password',
    method: 'post',
    data: _data,
    params,
    headers: {
      ...getPresetPropsWithBase64Format()
    }
  })
}

// 验证码登录
export function codeLogin(data, params = {}) {
  let _data = resolvePassword(data)
  return request({
    url: '/auth/v1/public/login/sms',
    method: 'post',
    data: _data,
    params,
    headers: {
      ...getPresetPropsWithBase64Format()
    }
  })
}

// 三方登录
export function otherLogin(data, params = {}) {
  let _data = resolvePassword(data)
  return request({
    url: "/auth/v1/public/login/social_login",
    method: "post",
    data: _data,
    params,
    headers: {
      ...getPresetPropsWithBase64Format(),
    },
  });
}

// 获取验证码
export function sendSMS(data) {
  return request({
    url: '/auth/v1/public/sms/send',
    method: 'post',
    data,
    headers: {
      ...getPresetPropsWithBase64Format()
    }
  })
}

// 绑定手机号 (第三方登录)
export function bindMobile(data) {
  let _data = resolvePassword(data)
  return request({
    url: '/auth/v1/public/signup/bind_social_code_mobile',
    method: 'post',
    data: _data,
    headers: {
      ...getPresetPropsWithBase64Format()
    }
  })
}

// 注册
export function register(data) {
  let _data = resolvePassword(data)
  return request({
    url: "/auth/v1/public/signup/mobile",
    method: "post",
    data: _data,
    headers: {
      ...getPresetPropsWithBase64Format(),
    },
  });
}
//重置密码
export function resetPwd(data) {
  let _data = resolvePassword(data)
  return request({
    url: "/auth/v1/public/user/reset_password",
    method: "post",
    data: _data,
    headers: {
      ...getPresetPropsWithBase64Format(),
    },
  });
}

// 一键登录(H5) 获取AuthToken

export function quickVerify(data) {
  return request({
    url: "/auth/v1/public/login/h5_ali_auth_token",
    method: "get",
    params: data,
    headers: {
      ...getPresetPropsWithBase64Format(),
    },
  });
}

// 一键登录 (H5)


export function quickLogin(data) {
  return request({
    url: "/auth/v1/public/login/h5_mobile_token",
    method: "post",
    data: data,
    headers: {
      ...getPresetPropsWithBase64Format(),
    },
  });
}