<template>
  <van-button
    round
    class="login-btn"
    @click="handle()"
    :disabled="disBtn"
    :loading="loading" 
    type="primary">{{btnStr}}</van-button>
</template>

<script>
export default {
  name: 'submit',
  props: {
    disBtn: {
      type: Boolean,
      default: true
    },
    btnStr: {
      type: String,
      default: '立即登录'
    },
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    handle(e) {
      this.loading = true
      this.$emit('submit', e)
    },
  }
}
</script>